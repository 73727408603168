@font-face {
  font-family: "AngelicBonques";
  src: local("AngelicBonques"),
    url(./fonts/angelic_bonques_sans/AngelicBonquesSansRegular.otf)
      format("opentype");
}

@font-face {
  font-family: "AngelicBonques";
  font-style: italic;
  src: local("AngelicBonques"),
    url(./fonts/angelic_bonques_sans/AngelicBonquesSansItalic.otf)
      format("opentype");
}

@font-face {
  font-family: "AngelicBonquesScript";
  src: local("AngelicBonques"),
    url(./fonts/angelic_bonques_sans/AngelicBonquesScript.otf)
      format("opentype");
}
